export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      WHP
      selection
      brand
      description
      createdAt
      updatedAt
      owner
      composition
    }
  }
`;

export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      WHP
      selection
      checkoutId
      brand
      RRP
      composition
      description
      createdAt
      updatedAt
      owner
    }
  }
`;

export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      SKU
      color
      items
      price
      WHP
      selection
      brand

      description
      createdAt
      updatedAt
      owner
      composition
    }
  }
`;

export const deleteSelection = /* GraphQL */ `
  mutation DeleteSelection(
    $input: DeleteSelectionInput!
    $condition: ModelSelectionConditionInput
  ) {
    deleteSelection(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      owner
    }
  }
`;

export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
    }
  }
`;
