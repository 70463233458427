export const countPrice = (items = [], WHP = Number) => {
  const multipledPrices = items.map((item) =>
    Object.values(item).map((value) => value * WHP)
  );

  return multipledPrices.reduce(
    (acc, price) => parseInt(acc) + parseInt(price),
    0
  );
};

export const numOr0 = (n) => (parseInt(n) ? n : 0);

export const countQuantity = (parsedItems) => {
  const reducedValues = parsedItems.map((items) =>
    items.map((item) =>
      Object.values(item).reduce((acc, value) => numOr0(acc) + numOr0(value), 0)
    )
  );

  const sumValues = reducedValues.map((array) =>
    array.reduce((acc, item) => parseInt(acc) + parseInt(item), 0)
  );

  const quantity = sumValues.reduce(
    (acc, item) => parseInt(acc) + parseInt(item),
    0
  );

  return quantity;
};
