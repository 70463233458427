import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, MenuItem } from "@material-ui/core";
const Select = ({
  label = "",
  onChange,
  value = "",
  options = [],
  className,
  name = "",
  helperText = "",
  ...others
}) => {
  const mappedOptions = options.map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ));
  return (
    <TextField
      select
      label={label}
      InputProps={{ disableUnderline: true }}
      SelectProps={{ IconComponent: ExpandMoreIcon }}
      onChange={onChange}
      value={value || ""}
      className={className}
      name={name}
      helperText={helperText}
      {...others}
    >
      {mappedOptions}
    </TextField>
  );
};
export default Select;
